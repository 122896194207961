import * as React from 'react';
import {useCanData} from '../../stores/CanData/CanDataProvider';
import IconButton from '@material-ui/core/IconButton';
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, SvgIcon} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {CanDataList} from './CanDataList';
import './CanData.scss';
import {useDeviceContext} from "../../stores/TelematicsDevice/TelematicsDeviceProvider";
import {TUButton} from "../../../../components/TUButton";
import {Refresh} from "@material-ui/icons";
import {isFirmwareGreaterThanOrEqualTo} from "../../stores/TelematicsDevice/FirmwareComparison";
import {ActivityDetectionType} from "../../stores/TelematicsDevice/DeviceStateApi";
import {useLegacyTelematicsDeviceContext} from "../../stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider";
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";
import { DeviceTypeEnum } from '../../stores/TelematicsDevice/DeviceTypeEnum';

export const CanData: React.FC = () => {
    const {identity, state} = useDeviceContext();
    const {accessibility} = useAccessibilityContext();
    const {canData, isLoading, reFetchLatestData} = useCanData(identity.serialNumber);
    const [lastCanDataDate, setLastCanDataDate] = React.useState(new Date(0));
    const [showCanDataDialog, setShowCanDataDialog] = React.useState(false);
    const telematicsDevice = useLegacyTelematicsDeviceContext().telematicsDevice;
    const handleOpenDialog = () => setShowCanDataDialog(true);
    const handleCloseDialog = () => setShowCanDataDialog(false);

    React.useEffect(() => {
        if (canData && canData.length > 0) {
            setLastCanDataDate(canData.map(d => d.latestData.timestamp).reduce(function (a, b) {return a > b ? a : b;}));
        }
    }, [canData]);

    const parseCanActivityDetectionState = (activityDetectionState: ActivityDetectionType | undefined) : string => {
        if(
          identity.deviceType === DeviceTypeEnum.TU700
          || identity.deviceType === DeviceTypeEnum.GRIFFIN
          || (isFirmwareGreaterThanOrEqualTo(state.reported.firmware?.version ?? telematicsDevice?.firmwareVersion , 64, 4)
            && (identity.deviceType === DeviceTypeEnum.TU500
              || identity.deviceType === DeviceTypeEnum.TU501
              || identity.deviceType === DeviceTypeEnum.TU600))) {

            switch (activityDetectionState) {
                case ActivityDetectionType.ACTIVITY_DETECTED:
                    return "Yes";
                    break;
                case ActivityDetectionType.NO_ACTIVITY_DETECTED:
                    return "No";
                    break;
                default:
                    return "Unknown";
            }
        }
        else {
            return "Unsupported";
        }
    }

    const getBackwardCompatibleDetectionState = () : ActivityDetectionType | undefined => {
        // There's two formatting of the Activity Detection State.
        // The below code will handle both and enable a smooth transition to the new syntax

        // Handle V1 syntax
        if( state.reported.canbus?.activityDetection && !state.reported.canbus.activityDetection?.hasOwnProperty("state") ) {
            return state.reported.canbus.activityDetection;
        }

        // Handle V2 syntax
        if( state.reported.canbus?.activity?.canActivityState )
            return state.reported.canbus.activity.canActivityState;

        // Handle V3 syntax
        return state.reported.canbus?.activityDetection?.state;
    }

    return (
        <>
            <dl className="staticSection">
                {accessibility?.activityDetection.visible && (<>
                    <dt>CAN activity:</dt>
                    <dd>{parseCanActivityDetectionState( getBackwardCompatibleDetectionState())}</dd>
                </>) }

                {accessibility?.can1ActivityDetection.visible && (<>
                    <dt>CAN #1 activity:</dt>
                    <dd>{parseCanActivityDetectionState(state.reported.canbus?.can1?.activityDetection?.state)}</dd>
                </>) }

                {accessibility?.can2ActivityDetection.visible && (<>
                    <dt>CAN #2 activity:</dt>
                    <dd>{parseCanActivityDetectionState(state.reported.canbus?.can2?.activityDetection?.state)}</dd>
                </>) }

                <dt>Last CAN data:</dt>
                {!isLoading() && canData && canData.length > 0 ? (
                  <dd className="can-data-text">
                      {lastCanDataDate.toLocaleString()}
                      <IconButton
                        onClick={reFetchLatestData}
                        aria-label="more"
                        className="can-data-list-details"
                      >
                          <Refresh color="primary"/>
                      </IconButton>
                      <IconButton
                        onClick={handleOpenDialog}
                        aria-label="more"
                        className="can-data-list-details"
                      >
                          <SvgIcon color="primary">
                              <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/>
                          </SvgIcon>
                      </IconButton>
                  </dd>
                ) : (
                  <dd>
                      {isLoading() ? (
                        <CircularProgress size={13}/>
                      ) : (
                        <span>
                                No data received
                                <IconButton
                                  onClick={reFetchLatestData}
                                  aria-label="more"
                                  className="can-data-list-details"
                                >
                                    <Refresh color="primary"/>
                                </IconButton>
                            </span>
                      )}
                  </dd>
                )}
            </dl>

            <Dialog
              open={showCanDataDialog}
              onClose={handleCloseDialog}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
            >
                <DialogTitle id="scroll-dialog-title">CAN data</DialogTitle>
                <DialogContent>
                    <CanDataList canData={canData} />
                </DialogContent>
                <DialogActions>
                    <TUButton
                        loading={isLoading()}
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={reFetchLatestData}
                        autoFocus
                    >
                        Refresh
                    </TUButton>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleCloseDialog}
                        autoFocus
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
